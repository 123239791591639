import StyledComponents from "../../../utils/sharedStyles";

const { InfoBox } = StyledComponents;
function Preface() {
  return (
    <InfoBox>
      <p>
        <em>
          Помещённое здесь предисловие первоначально было напечатано в сборнике
          «Hymns» (Living Stream Ministry, Anaheim, California, U.S.A., 1966),
          включавшем в себя 1080 гимнов, содержание которого составляет основу
          настоящего сборника на русском языке. Предисловие печатается с
          незначительными сокращениями.
        </em>
      </p>

      <p>
        Христианские гимны и песни представляют собой выражение святых и
        духовных чувств, которые формируются у святых в результате их
        переживаний в Христе, познания ими Бога и Его истины, а также в
        результате их восхищения, хвалы и благодарения, обращённых к Богу Отцу и
        Господу Иисусу Христу. Они необходимы как для христианских собраний, так
        и для личной христианской жизни, чтобы с их помощью достигать следующих
        целей:
      </p>

      <p>
        <ol>
          <li>Поклоняться Богу Отцу</li>
          <li>Хвалить Господа Иисуса Христа</li>
          <li>Назидать святых</li>
          <li>Созидать церковь</li>
          <li>Вести духовную войну</li>
          <li>Возвещать благую весть.</li>
        </ol>
      </p>

      <p>
        Церковь существует на земле уже почти двадцать столетий. За такой
        продолжительный период времени во всех столетиях было создано множество
        хороших гимнов и песен, которые были написаны многими святыми,
        обладавшими определёнными духовными переживаниями и знаниями. Следуя по
        их стопам, мы получили это благословение — наслаждаться всеми их
        сочинениями как богатым наследием. Поэтому в данном сборнике мы
        постарались, насколько это возможно, собрать все их произведения, чтобы
        сегодняшняя церковь пользовалась и наслаждалась всем богатством Божьего
        дома. Мы благодарны за все эти произведения как их авторам, так и нашему
        Отцу — Тому, кто даёт все дары.
      </p>

      <p>
        Мы попытались включить в этот сборник все классические, хорошие, здравые
        и духовные гимны и песни за исключением нескольких, оказавшихся
        недоступными для нас из-за отсутствия права на публикацию. В ходе этой
        работы были отобраны более 700 гимнов прошлых столетий. В дополнение к
        этому мы постарались выделить достойные фрагменты из многих духовных
        сочинений, которые можно было бы положить на музыку и петь в качестве
        гимнов. Благодаря этим источникам было выбрано и аранжировано
        значительное число произведений, в результате чего их общее число
        увеличилось до 800 с лишним. Мы убеждены в том, что данный сборник
        содержит «сливки» всех прошлых столетий христианских переживаний и
        постижения истины в гимнах и песнях.
      </p>

      <p>
        При отборе гимнов их тексты были тщательно изучены и в них было внесено
        довольно большое число изменений (за исключением тех случаев, когда
        этому препятствовал вопрос авторских прав) с целью повысить точность
        передачи истины и обогатить духовность их смысла. Впрочем, в этой тонкой
        и трудной работе мы старались не отступать от авторского смысла и текста
        за исключением тех случаев, когда мы считали это необходимым.
      </p>

      <p>
        На пути Господнего восстановления познание Господа и Его истины церковью
        с каждым днём продвигается всё дальше и святые постоянно делают новые
        открытия в духовных переживаниях. Хотя число гимнов и песен, сочинённых
        в прошлом, велико и среди них немало шедевров, они не соответствуют
        нынешнему уровню Господнего восстановления и во многих отношениях не
        способны удовлетворить сегодняшние возросшие нужды. За прошедшие сорок
        лет Господь решительно восстанавливал истину и переживания в отношении
        Христа как жизни и церкви как выражения Христа, и именно эти два момента
        Господь сегодня особым образом подчёркивает и пытается восстановить в
        полной мере среди Своих людей. Наряду с этими моментами были также
        восстановлены некоторые жизненно важные аспекты внутренней жизни, такие
        как знание и различение духа, упражнение духа, высвобождение духа и т.
        д. Среди гимнов и песен прошлого трудно, а иногда и невозможно найти
        такие, которые могут выразить наши чувства, переживания и знания в этих
        аспектах. Унаследованные из прошлых столетий гимны для поклонения Богу
        Отцу и для хвалы Личности Господа Иисуса также очень немногочисленны.
      </p>

      <p>
        Для удовлетворения насущной потребности в гимнах, достаточно хороших с
        точки зрения этих аспектов, мы приложили усилия к тому, чтобы перевести
        ряд гимнов, написанных за последние сорок лет на китайском языке, а
        также сочинить ряд новых гимнов, что позволило бы нам восполнить
        существующий пробел и поднять на нынешний уровень то, что мы хотим
        выразить в гимнах и песнях. Разумеется, это было сделано настолько,
        насколько позволяли благодать и время. Число переведённых нами гимнов —
        33, а число новых гимнов, сочинённых нами, — 244. Таким образом, общее
        число гимнов — 1080. В целях разграничения все переведённые гимны
        помечены крестиком (†), а все новые сочинения — звёздочкой (*). С учётом
        переведённых гимнов и новых сочинений этот сборник можно считать
        достаточно полным: на сегодняшний день он охватывает большинство сторон
        истины и переживаний.
      </p>

      <p>
        Мы разделили все гимны и песни на тридцать основных рубрик, порядок
        которых соответствует истине и духовным переживаниям. Все эти рубрики, в
        свою очередь, были тщательно и детально разделены на 400 подразделов с
        соответствующими заголовками, что облегчает выбор подходящих гимнов во
        время собраний и в других случаях, а также помогает понять главную мысль
        каждого гимна. Заголовки рубрик и подразделов приведены в содержании и
        напечатаны в верхней части страниц сборника. Мы также попытались
        расставить в надлежащем порядке гимны внутри каждого подраздела, однако
        не всегда оказалось возможным строго придерживаться такой расстановки.
      </p>

      <p>
        В конце сборника помещён указатель первых строк всех гимнов с первыми
        строками припевов в алфавитном порядке, что помогает найти тот или иной
        гимн, вспомнив либо его первую строку, либо первую строку его припева.
      </p>

      <p>
        Мы постарались включить в настоящий сборник как можно больше хороших,
        подходящих для пения и соответствующих нашим целям мелодий. В сборник
        вошла большая часть старых, знакомых мелодий, многие из которых
        использованы для гимнов из числа новых сочинений и переводов. В сборнике
        есть значительное число мелодий, которые многим будут незнакомы, но мы
        полагаем, что, разучив эти мелодии, читатели оценят их по достоинству. В
        тех случаях, когда мы сочли возможным сделать музыку более лёгкой для
        пения и более гармоничной, были внесены незначительные изменения в
        мелодию, ритм и гармонию.
      </p>

      <p>
        Большое внимание было уделено подбору мелодий к гимнам с учётом как
        популярности мелодий, так и их приемлемости. Во многих случаях к тексту
        гимна напечатаны две мелодии, когда известно, что данный гимн часто
        поётся на обе мелодии, или когда расположение гимна на странице
        позволяет разместить вторую мелодию, которой может быть отдано
        предпочтение перед первой. Составители сборника рекомендуют петь каждый
        гимн на ту мелодию или мелодии, которые приведены в сборнике. Однако под
        нотами к некоторым гимнам указаны номера альтернативных мелодий, которые
        могут быть более известными или предпочтительными.
      </p>

      <p>
        С нашей точки зрения, когда строки каждого куплета печатаются рядом с
        соответствующими нотными строками, как это делается в большинстве
        американских сборников гимнов, вдоховение и смысл теряются из-за
        необходимости искать глазами нужный куплет среди других куплетов при
        переходе с одной строки на другую, а также из-за разбивки слов на слоги
        и уплотнения текста, неизбежных при таком расположении. Когда каждый
        куплет можно прочитать целиком, не отвлекаясь подобным образом, смысл
        гимна воспринимается глазами как непрерывный поток и это способствует
        вдохновению. По этой причине рядом с нотными строками напечатан только
        первый куплет, чтобы показать, как следует петь данный гимн.
      </p>

      <p>
        Читатель заметит, что мы удалили текст для голосовых партий альта,
        тенора и баса, который обычно используется во многих гимнах. Это было
        сделано для того, чтобы читатель не отвлекался от смысла гимна и его
        духа, что бывает, когда во время пения разум чрезмерно сосредотачивается
        на музыке, а не на словах и духе. Мы считаем необходимым объяснить
        отсутствие имени автора слов и композитора в заголовке каждого гимна.
        Это было сделано в следующих целях: 1) чтобы каждый гимн был как можно
        менее загружен какойлибо побочной информацией и перед глазами читателя
        была отчётливо воспринимаемая мысль гимна, от которой он как можно
        меньше отвлекался бы; 2) чтобы удалить любую информацию, которая могла
        бы склонить читателя воздать славу человеку, а не Господу — истинному
        сочинителю каждого гимна.
      </p>

      <p>
        Мы очень благодарны многим издателям, любезно предоставившим нам
        разрешение использовать их гимны, стихи и мелодии (некоторые
        предоставили его безвозмездно). Мы сделали всё возможное с нашей
        стороны, чтобы найти держателей авторских прав с целью получить у них
        разрешение на использование их произведений. Если мы непреднамеренно
        упустили когото из них из виду и включили в сборник их произведения без
        соответствующего разрешения и ссылки, мы просим этих держателей
        авторских прав проявить снисходительность. По получении уведомления мы
        дадим должную ссылку в последующих изданиях.
      </p>

      <p>
        Все переведённые нами гимны, помеченные крестиком (†), и все наши новые
        сочинения, помеченные звёздочкой (*), безвозмездно предоставляются для
        использования всеми детьми Божьими. Однако во избежание недоразумений мы
        просим проконсультироваться со служением «Живой поток», прежде чем они
        будут перепечатываться.
      </p>

      <p>
        Мы считаем своим долгом выразить глубокую благодарность многим братьям и
        сёстрам, оказавшим ценную помощь в обширной работе над книгой,
        включавшей в себя сбор гимнов, их сортировку, перевод, редактирование
        текста, корректировку нот, рукописное копирование, машинопись,
        фотографирование, корректуру и печать. Этот сборник гимнов поистине был
        выпущен в координации по принципу Тела. Да будет вся слава Главе,
        Божьему славному Христу!
      </p>

      <p>
        Эта книга была подготовлена с целью удовлетворить потребности любых
        христианских собраний за исключением, может быть, детских собраний (для
        которых, как мы полагаем, святые могут приготовить более подходящие
        дополнительные материалы в соответствии с их конкретными потребностями),
        а также для использования в домашней жизни и в личном общении святых с
        Господом. Мы хотели бы особо подчеркнуть этот последний аспект
        использования сборника, поскольку он может стать источником большого
        благословения для отдельного человека и источником удовлетворения для
        Господа и поскольку он является упражнением, которое, к великому
        сожалению, упускается и которым пренебрегают. Мы настоятельно
        воодушевляем всех, у кого есть экземпляр этой книги, использовать её
        наряду с Библией во время общения с Господом. После того как мы
        насытились Господом в Его слове, полезно выразить наше переживание
        Господа или нашу хвалу Ему в словах подходящего гимна, который следует
        петь, обращая внимание не столько на музыкальную сторону, сколько на
        духовную.
      </p>

      <p>
        Мы также хотели бы воодушевить святых уделять время разучиванию новых
        гимнов и мелодий. Затраченные усилия вполне оправдают себя. Благодаря
        тому что мы расширяем своё знакомство с достойными гимнами, расширяется
        наш запас средств выражения и наши собрания и наше личное общение
        становятся богаче. Мы поклоняемся Ему за Его чудесные пути и благодарим
        Его за Его милость и благодать, которые позволили осуществить этот труд
        в нынешний час нужды. Мы с верностью отдаём эту книгу в Его
        благословляющую руку, искренне ожидая с обильной молитвой, что Он
        использует этот скромный труд, чтобы благословить Своих святых и Свои
        церкви ради более успешного продвижения Его восстановления и ради
        ускорения пришествия дня славы.
      </p>

      <p>
        Составители и авторы —<br />
        Уитнесс Ли и редакционный отдел
      </p>

      <p>
        Лос-Анджелес, США
        <br />
        июль 1966 г.
      </p>
    </InfoBox>
  );
}

export default Preface;
